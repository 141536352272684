body
    --text-color: rgb(37 33 107)
    --border-radius: 8px
    --border-color: #F3EEF3
    --border-color-hover: #292929

    --app-bg-color: rgb(243 238 243)

    --secondary-color-hsl: 204.6,35%,31.4%
    --secondary-color: hsl(var(--secondary-color-hsl))
    --secondary-color-hover: hsla(var(--secondary-color-hsl), 0.8)
    --secondary-color-outline: hsla(var(--secondary-color-hsl), 1)
    --secondary-color-active: hsla(var(--secondary-color-hsl), 0.9)
    --secondary-color-deprecated-bg: hsla(var(--secondary-color-hsl), 0.2)
    --secondary-color-deprecated-border: var(--secondary-color)


    --secondary-color-disabled: #ebe5dd
    --secondary-1: hsla(var(--secondary-color-hsl), 0.1)
    --secondary-2: hsla(var(--secondary-color-hsl), 0.2)
    --secondary-3: hsla(var(--secondary-color-hsl), 0.3)
    --secondary-4: hsla(var(--secondary-color-hsl), 0.4)
    --secondary-5: hsla(var(--secondary-color-hsl), 0.5)
    --secondary-6: hsla(var(--secondary-color-hsl), 0.6)
    --secondary-7: hsla(var(--secondary-color-hsl), 0.7)
    --secondary-8: hsla(var(--secondary-color-hsl), 0.8)
    --secondary-9: hsla(var(--secondary-color-hsl), 0.9)
    --secondary-10: hsl(var(--secondary-color-hsl))



.ant-tooltip > .ant-tooltip-content
  > .ant-tooltip-inner
    background: white
    color: $text-primary-color
    > ul.list-item-purchase-history, ol.list-item-purchase-history
      min-width: 300px
    > ul.list-item-purchase-history
      padding-left: 0
    > ol.list-item-purchase-history
      padding: 0 15px
  .ant-tooltip-arrow-content::before 
    background: white

        
    


.ant-popover-buttons
  display: flex
.ant-popover-inner
  border-radius: 4px

.ant-popover-title
  font-size: 12px
  font-weight: 600
  color: var(--text-color)
  padding-top: 1rem
  padding-bottom: 1rem

.ant-popover-inner-content
  font-size: 12px
  color: var(--text-color)


// #================#
// # ==> TABLE <== #
// #================#

.ant-table table
  border-spacing: 0 3px

.ant-table-thead > tr > th
  height: 16px
  color: $text-primary-color
  font-size: 12px
  font-weight: 600
  background: white

.ant-table-thead .ant-checkbox-indeterminate .ant-checkbox-inner::after 
  transform: translate(-50%, -50%) scale(1) !important
  background-image: none !important

.ant-table-tbody > tr:nth-child(2n)
  background: rgb(245 250 254)

.ant-table-tbody > tr
  background: white

.ant-table-tbody > tr.ant-table-row
  > td
    &:first-child
      border-top-left-radius: 4px
      border-bottom-left-radius: 4px
      border-left: 1px solid transparent
    // &:last-child
    //   border-top-right-radius: 4px
    //   border-bottom-right-radius: 4px
    //   border-right: 1px solid transparent
  &:hover
    > td
      //border-color: $border-color-hover
      background: transparent

      //&:first-child
        //border-left-color: $border-color-hover
      //&:last-child
        //border-right-color: $border-color-hover

.ant-table-tbody > tr.ant-table-row-selected
  > td
    background: transparent
  &:hover
    > td
      background: rgb(204, 210, 255)

.ant-table-tbody > tr > td
  height: 16px
  color: $text-primary-color
  font-size: 12px
  border-top: 1px solid transparent
  border-bottom: 1px solid transparent

  .ant-table-sell
    margin: 5px 0
    .ant-table-row-selected
      border: 1px solid
      border-radius: 2px

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td
  padding: 10px
.ant-table-thead>tr>th
  border-bottom: 0
.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before
  width: 0

.scroll-table 
  /* width */
  ::-webkit-scrollbar 
    width: 4px

  /* Track */
  ::-webkit-scrollbar-track 
    background: #ffffff00
    border-radius: 4px

  /* Handle */
  ::-webkit-scrollbar-thumb 
    background: rgba(0, 0, 0, 0.5)
    border-radius: 4px

  .ant-spin-container, .ant-table-container
    display: flex
    flex-direction: column
  
  .ant-table-wrapper
    overflow: hidden
    height: fit-content
  
  .ant-table,
  .ant-table-container
    height: 100%
    overflow: hidden
  
  .ant-table-body
    overflow-y: auto !important
    max-height: unset !important

    mask-image: linear-gradient(to top, transparent 1000px, black), linear-gradient(to left, transparent 4.5px, black 0px)
    mask-size: 100% 10000px
    mask-position: left bottom
    -webkit-mask-image: linear-gradient(to top, transparent 1000px, black), linear-gradient(to left, transparent 4.5px, black 0px)
    -webkit-mask-size: 100% 10000px
    -webkit-mask-position: left bottom
    transition: mask-position 0.3s, -webkit-mask-position 0.3s
    transition-delay: 1s

  .ant-table-body:hover
    -webkit-mask-position: left top
    transition: mask-position 0s, -webkit-mask-position 0s

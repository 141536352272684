.ant-menu
  color: $text-primary-color
.ant-menu-horizontal
  border: 0
.ant-menu-horizontal > .ant-menu-item:hover::after,
.ant-menu-horizontal > .ant-menu-submenu:hover::after,
.ant-menu-horizontal > .ant-menu-item-active::after,
.ant-menu-horizontal > .ant-menu-submenu-active::after,
.ant-menu-horizontal > .ant-menu-item-open::after,
.ant-menu-horizontal > .ant-menu-submenu-open::after,
.ant-menu-horizontal > .ant-menu-item-selected::after,
.ant-menu-horizontal > .ant-menu-submenu-selected::after
  border-bottom: 0 solid transparent !important

.ant-menu-inline .ant-menu-item-selected:after, .ant-menu-inline .ant-menu-selected:after, .ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-vertical .ant-menu-item:after
  border: none
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left
  border: none
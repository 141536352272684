.flex
  display: flex

.flex-a-center
  display: flex
  align-items: center

.flex-a-start
  display: flex
  align-items: flex-start

.flex-a-end
  display: flex
  align-items: flex-end

.flex-j-center
  display: flex
  justify-content: center

.flex-j-start
  display: flex
  justify-content: flex-start

.flex-j-end
  display: flex
  justify-content: end

.flex-j-between
  display: flex
  justify-content: space-between

.flex-center
  display: flex
  align-items: center
  justify-content: center

.flex-center-start
  display: flex
  align-items: center
  justify-content: flex-start

.flex-center-between
  display: flex
  align-items: center
  justify-content: space-between

.flex-column
  display: flex
  flex-direction: column

.flex-column-center
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center


.ant-notification
  .ant-notification-notice
    display: flex
    align-items: center
    position: relative
    border-radius: $border-radius
    width: 440px
    min-height: 64px
    .ant-notification-notice-message
      margin-bottom: 0
      margin-left: 62px
      font-weight: 600
      width: 294px
      padding: 0
    .ant-notification-notice-icon
      z-index: 1
      display: flex
      position: absolute
      top: 20px
      left: 20px
      margin: 0
      border-radius: 50%
      //border: 2px solid white
      background: white
      width: 22px
      height: 22px
      align-items: center
      justify-content: center
    .ant-notification-notice-close
      top: calc(50% - 6px)
      right: 20px
      .ant-notification-close-x
        display: flex
    
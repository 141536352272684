.ant-modal-title
  font-weight: 600
  color: $text-primary-color
.ant-form-item-label label
  color: $text-primary-color
.ant-modal-footer
  display: flex
  justify-content: center
  button
    width: 100%
    max-width: 600px
    

.ant-modal-content 
  border-radius: $border-radius
  .ant-modal-close
    color: $primary-color
    top: 7px

  .ant-modal-header
    border-radius: $border-radius $border-radius 0 0
    padding: 24px
    .ant-modal-title
      text-align: center
      font-size: 16px
      line-height: 28px
      font-weight: 600
  .ant-modal-footer
    padding: 1.714rem

.ant-dropdown
  .ant-dropdown-menu
    border-radius: $border-radius
    padding: 7px 0
    .ant-dropdown-menu-item
      font-size: $font-size
      font-weight: 600
      letter-spacing: 0
      line-height: 16px
      margin: 6px
      display: flex
      align-items: center

      &:not(.ant-dropdown-menu-item-disabled)
        color: $text-primary-color


.custom-select-popup
  display: none
  
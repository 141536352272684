// #================#
// # ==> TAG <== #
// #================#
.ant-tag
  min-height: 33px
  border-radius: 6px
  color: $text-primary-color
  font-size: 12px
  font-weight: 600
  display: flex
  align-items: center
  justify-content: center
  padding: 0 12.5px
  margin-right: 16px
  white-space: pre-line
  background: var(--ant-primary-color-deprecated-f-12)
  //border-color: var(--ant-primary-color)


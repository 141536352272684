
.border
  border: 1px solid $border-color

.flex
  display: flex
.center
  justify-content: center
.align-center
  align-items: center
.place-holder-center
  .ant-input::placeholder
    text-align: center

.unstyle-disabled
  cursor: default !important
  background: inherit !important
  border-color: $border-color !important
  color: inherit !important
  span,
  .ant-select-selector,
  input[disabled]
    cursor: default !important
    border-color: #d9d9d9 !important
    color: inherit !important
  .ant-select-selector
    background: inherit !important


.brown-text-color
  color: $primary-color !important

.main-text-color
  color: $text-primary-color

.line
  width: 100%
  height: 1px
  background-color: $border-color

.required-label
  font-size: 12px
  color: $text-primary-color
  font-weight: 600
  margin-right: 0.5rem
  &:before
    display: inline-block
    margin-right: 4px
    color: var(--ant-error-color)
    font-size: $font-size
    font-family: SimSun, sans-serif
    line-height: 1
    content: '*'

.active-status
  background: var(--ant-primary-color-deprecated-f-12)
  padding: 0.5rem 1rem
  border-radius: 6px

.full-width
  width: 100%
.child-span-force-full-width
  >span:nth-child(2)
    width: 100%

.label-input
  color: #25216A
  font-size: 12px
  font-weight: bold

div[data-simplebar='init']
  &::-webkit-scrollbar
    display: none


.ant-card
  background: #ffffff
  border-radius: $border-radius
  padding: $padding-card
  color: $text-primary-color
  & > .ant-card-head
    border-bottom: 0
    padding: 0
    & > .ant-card-head-wrapper
      & > .ant-card-head-title
        font-size: 1.429rem
        color: $text-primary-color
        font-weight: 600
  & > .ant-card-body
    height: 100%
    padding: 0
.ant-card-bordered
  border-color: $border-color
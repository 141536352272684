.anticon
//  cursor: pointer

.custom-turn-right-icon
  width: 1.2rem
  height: 1.2rem
  border-radius: 1.2rem
  background: $primary-color
  color: white
  padding: 0.2rem
  margin-left: 0.5rem

.form-item-container
  .anticon-close-circle
    pointer-events: auto

// #================#
// # ==> BUTTON <== #
// #================#
.ant-pagination
  .ant-pagination-total-text
    color: $text-primary-color
  .ant-pagination-item-link
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    .ant-pagination-item-container
      width: 100%
      height: 100%
      display: flex
      align-items: center
      justify-content: center

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-item
    border-radius: 6px
    a
      color: $text-primary-color

  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus .ant-pagination-item-link
    border-radius: 6px




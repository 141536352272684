.ant-modal-confirm-body .ant-modal-confirm-content
  color: $text-primary-color
  font-weight: 600
.ant-modal-confirm-btns
  display: flex
  justify-content: flex-end
.ant-modal-confirm-btns > .custom-ok-btn
  display: inline-flex
  border-radius: 6px
  color: $text-primary-color
  border: 1px solid $text-primary-color
  align-items: center
  justify-content: center
  position: relative
  box-shadow: none
  height: 2.286rem
  padding: 0
  span
    font-size: 12px
    font-weight: 600
    margin: auto 1.143rem
    &.ant-btn-loading-icon
      margin: 0 !important
      > span
        margin: 0